import React, { useEffect, useRef } from "react";
import { funnerBigLogo } from "../libs/images";

const Preloader = ({ speed = 6, scaleLimit = 0.95 }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.src = funnerBigLogo;

    let animationFrameId;
    let scale = scaleLimit;
    let direction = -1;

    const resizeCanvas = () => {
      canvas.width = canvas.parentElement.clientWidth;
      canvas.height = canvas.parentElement.clientHeight;
      drawImageToFitCanvas();
    };

    const drawImageToFitCanvas = () => {
      const aspectRatio = image.width / image.height;
      let imgWidth = canvas.width * scaleLimit;
      let imgHeight = imgWidth / aspectRatio;

      if (imgHeight > canvas.height * scaleLimit) {
        imgHeight = canvas.height * scaleLimit;
        imgWidth = imgHeight * aspectRatio;
      }

      const x = (canvas.width - imgWidth) / 2;
      const y = (canvas.height - imgHeight) / 2;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, x, y, imgWidth, imgHeight);
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      scale += (speed / 5000) * direction;
      if (scale > 1 || scale < scaleLimit) {
        direction *= -1;
      }

      const aspectRatio = image.width / image.height;
      let imgWidth = canvas.width * scale;
      let imgHeight = imgWidth / aspectRatio;

      if (imgHeight > canvas.height * scale) {
        imgHeight = canvas.height * scale;
        imgWidth = imgHeight * aspectRatio;
      }

      const x = (canvas.width - imgWidth) / 2;
      const y = (canvas.height - imgHeight) / 2;
      ctx.drawImage(image, x, y, imgWidth, imgHeight);

      animationFrameId = requestAnimationFrame(animate);
    };

    image.onload = () => {
      resizeCanvas();
      animate();
    };

    window.addEventListener("resize", resizeCanvas);

    return () => {
      cancelAnimationFrame(animationFrameId);
      window.removeEventListener("resize", resizeCanvas);
    };
  }, [speed, scaleLimit]);

  return (
    <div
      style={{
        width: "100%",
        height: "300px",
        maxWidth: 700,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <canvas
        ref={canvasRef}
        style={{ maxWidth: "100%", height: "100%" }}
      ></canvas>
    </div>
  );
};

export default Preloader;
