import React, { Fragment, useEffect, useRef, useState } from "react";
import { applicationType } from "../../../applicationType";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  SvgIcon,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IsJsonString } from "../../../libs/helpers";
import localizations from "../../../localizations.json";
import {
  powerlinkIcon,
  powerlinkIconOrange,
  priorityDocLogo,
  priorityLetterLogo,
} from "../../../libs/images";
import { Send } from "@mui/icons-material";
import reactDom from "react-dom";
import SmilesPanelBottom from "../SmilesPanel/SmilesPanelBottom";
import ChatInputAreaIcons from "./ChatInputAreaIcons";

function SendMessageForm({
  funnerUsers,
  textareaRef,
  // setTextareaValue,
  isTemplateSending,
  // textareaValue,
  sendToPlatformsStatus,
  isActive,
  selectedChatUser,
  handleUpload,
  userConfig,
  resetTemplateFromChat,
  toggleAccordion,
  chatType,
  toggleSmilesPanelBottom,
  businessInfo,
  setChooseFromGallery,
  handlePrioritySendDocsWindow,
  sendUpload,
  sendUploadPriority,
  isMessageSending,
  handleForm,
  resetTextArea,
  smilesPanelContainerRef,
  isSmileBottomPanelOpen,
}) {
  const [localization, setLocalization] = useState(
    localizations["he"].chat.inputArea,
  );
  const [textareaValue, setTextareaValue] = useState("");
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down(480));

  const [isNoteContacts, setIsNoteContacts] = useState(false);
  const [noteContactsList, setNoteContactsList] = useState([]);

  const sendBtnRef = useRef(null);

  const findContactTextAreaHandler = (e) => {
    const textarea = textareaRef.current;
    const inputText = textarea.value;
    const lastWord = inputText.split(" ").pop();

    if (funnerUsers?.length) {
      if (lastWord.indexOf("@") === 0) {
        setIsNoteContacts(true);

        const currentValue = lastWord.slice(1).toLowerCase();
        if (currentValue !== "") {
          const filteredArray = funnerUsers
            ?.filter((user) => {
              const userProfileConfig = IsJsonString(user.ProfileConfig)
                ? JSON.parse(user.ProfileConfig)
                : {};

              const visible =
                userProfileConfig?.funnerInfo?.visibleInList !== undefined &&
                userProfileConfig?.funnerInfo?.visibleInList !== null
                  ? userProfileConfig.funnerInfo.visibleInList
                  : user.IsActive;

              return visible;
            })
            .filter((contact) =>
              `${contact.FirstName || ""} ${contact.LastName || ""}`
                ?.toLowerCase()
                .includes(currentValue),
            );
          console.log(filteredArray);
          setNoteContactsList(filteredArray);
        } else {
          setNoteContactsList([
            ...funnerUsers
              .filter((user) => {
                const userProfileConfig = IsJsonString(user.ProfileConfig)
                  ? JSON.parse(user.ProfileConfig)
                  : {};

                const visible =
                  userProfileConfig?.funnerInfo?.visibleInList !== undefined &&
                  userProfileConfig?.funnerInfo?.visibleInList !== null
                    ? userProfileConfig.funnerInfo.visibleInList
                    : user.IsActive;

                return visible;
              })
              .filter(
                (contact) =>
                  `${contact.FirstName || ""} ${contact.LastName || ""}`,
              ),
          ]);
        }
      } else setIsNoteContacts(false);
    }
  };
  const adminUserListClick = (contact) => {
    const textarea = textareaRef.current;
    const userName = contact.FullName;
    const inputText = textarea.value;
    const lastWord = inputText.split(" ").pop();
    const lastWordPos = inputText.length - lastWord.length;

    const textareaNewText = inputText.slice(0, lastWordPos + 1) + userName;
    setTextareaValue(textareaNewText);
    setIsNoteContacts(false);
    textarea.focus();
  };

  const handleTextareaText = (e) => {
    const input = e.target;
    setTextareaValue(input.value);
  };
  const handleTextAreaKeyDown = (event) => {
    const currentSubmitType = userConfig.submitType;

    // const checkedSubmitType = submitType.find((type) => type.checked);
    const checkedSubmitTypeCtrlEnter = currentSubmitType?.ctrlEnter.find(
      (type) => type.checked,
    );
    const checkedSubmitTypeShiftEnter = currentSubmitType?.shiftEnter.find(
      (type) => type.checked,
    );
    const checkedSubmitTypeEnter = currentSubmitType?.enter.find(
      (type) => type.checked,
    );

    const selectedSubmitTypeCtrlEnter = checkedSubmitTypeCtrlEnter.text;
    const selectedSubmitTypeShiftEnter = checkedSubmitTypeShiftEnter.text;
    const selectedSubmitTypeEnter = checkedSubmitTypeEnter.text;

    const sendBtn = sendBtnRef.current;
    const isCtrlEnterMustSend = selectedSubmitTypeCtrlEnter === "שלח הודעה";
    const isShiftEnterMustSend = selectedSubmitTypeShiftEnter === "שלח הודעה";
    const isEnterMustSend = selectedSubmitTypeEnter === "שלח הודעה";

    if (
      //! Send if combination clicked
      (isCtrlEnterMustSend &&
        event.key === "Enter" &&
        (event.ctrlKey || event.metaKey)) ||
      (isShiftEnterMustSend && event.key === "Enter" && event.shiftKey) ||
      (isEnterMustSend &&
        event.key === "Enter" &&
        !event.ctrlKey &&
        !event.metaKey &&
        !event.shiftKey)
    ) {
      event.preventDefault();
      if (!event.target.value || event.target.value === "") return;
      sendBtn.click();
    } else if (
      //! Break line if combination clicked
      (!isCtrlEnterMustSend &&
        event.key === "Enter" &&
        (event.ctrlKey || event.metaKey)) ||
      (!isShiftEnterMustSend && event.key === "Enter" && event.shiftKey) ||
      (!isEnterMustSend &&
        event.key === "Enter" &&
        !event.shiftKey &&
        !event.metaKey)
    ) {
      event.preventDefault();
      // const newValue = textareaRef.current.value + "\n";
      // setTextareaValue(newValue);
      const textarea = textareaRef.current;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const text = textarea.value;
      const before = text.substring(0, start);
      const after = text.substring(end, text.length);

      // textarea.value = before + "\n" + after;
      setTextareaValue(before + "\n" + after);
      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = start + 1;
      }, 50);

      textarea.focus();
    }
  };
  function resetTextArea() {
    setTextareaValue("");
  }

  const copyPasteUpload = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    if (clipboardData.files && clipboardData.files.length > 0) {
      handleUpload(clipboardData.files);
    }
  };

  const onUserChangedActions = () => {
    resetTextArea();
  };

  useEffect(() => {
    onUserChangedActions();
  }, [selectedChatUser?.UserDisplayPhoneNumber]);

  return (
    <Fragment>
      <Grid
        container
        spacing={1.5}
        alignItems={applicationType === "web" ? "flex-end" : null}
      >
        <Grid
          item
          xs={applicationType === "web" ? null : 12}
          flexGrow={applicationType === "web" ? 1 : null}
        >
          <div className="form-group">
            <div
              className={`choose-contact-list-wrapper ${
                isNoteContacts && noteContactsList.length > 0 ? "active" : ""
              }`}
            >
              <ul className="choose-contact-list">
                {noteContactsList.map((contact) => (
                  <li
                    key={contact.Id}
                    onClick={() => adminUserListClick(contact)}
                  >
                    {contact.FullName}
                  </li>
                ))}
              </ul>
            </div>
            <TextField
              value={
                isTemplateSending
                  ? localization.textarea.templateMessageText
                  : textareaValue
              }
              placeholder={localization.textarea.placeholder}
              inputRef={textareaRef}
              onChange={handleTextareaText}
              InputProps={{
                readOnly:
                  (sendToPlatformsStatus !== "note" && !isActive) ||
                  !selectedChatUser ||
                  isTemplateSending,
                sx: {
                  fontSize: 14,
                },
                // onFocus: () => console.log("textarea focus"),
                // onBlur: () => console.log("textarea blur"),
              }}
              disabled={
                (sendToPlatformsStatus !== "note" && !isActive) ||
                !selectedChatUser ||
                isTemplateSending
              }
              onInput={(e) => {
                if (sendToPlatformsStatus === "note") {
                  findContactTextAreaHandler(e);
                }
              }}
              onPaste={copyPasteUpload}
              onKeyDown={handleTextAreaKeyDown}
              multiline
              minRows={1}
              maxRows={5}
              fullWidth
              size="small"
              sx={{
                bgcolor: "common.white",
              }}
            />
            {isTemplateSending && (
              <span
                className="delete-template-choice"
                onClick={resetTemplateFromChat}
              >
                x
              </span>
            )}
          </div>
        </Grid>
        <Grid item xs={applicationType === "web" ? "auto" : 12}>
          <div className="chat-input-actions__bottom">
            {applicationType === "extension" && (
              <Grid item>
                <ChatInputAreaIcons
                  businessInfo={businessInfo}
                  toggleAccordion={toggleAccordion}
                  selectedChatUser={selectedChatUser}
                  sendToPlatformsStatus={sendToPlatformsStatus}
                  isActive={isActive}
                  chatType={chatType}
                  handleUpload={handleUpload}
                  toggleSmilesPanelBottom={toggleSmilesPanelBottom}
                  setChooseFromGallery={setChooseFromGallery}
                  handlePrioritySendDocsWindow={handlePrioritySendDocsWindow}
                />
              </Grid>
            )}
            {((sendToPlatformsStatus === "note" && selectedChatUser) ||
              (sendToPlatformsStatus !== "note" && isActive)) && (
              <Button
                variant="contained"
                color={
                  sendToPlatformsStatus === "note" ? "customPurple" : "primary"
                }
                // disabled={
                //   (!textareaValue.length &&
                //     !sendUpload?.isUpload &&
                //     !sendUploadPriority?.isUpload) ||
                //   isMessageSending
                // }
                sx={{
                  height: 37,
                  minHeight: 37,
                  minWidth: matchesMobile ? "none" : 120,
                  pointerEvents:
                    (!textareaValue.length &&
                      !sendUpload?.isUpload &&
                      !sendUploadPriority?.isUpload) ||
                    isMessageSending
                      ? "none"
                      : null,
                  opacity:
                    (!textareaValue.length &&
                      !sendUpload?.isUpload &&
                      !sendUploadPriority?.isUpload) ||
                    isMessageSending
                      ? 0.5
                      : 1,
                  transition: (theme) =>
                    `${theme.transitions.create(
                      ["color", "background-color", "opacity"],
                      {
                        duration: theme.transitions.duration.short,
                      },
                    )}, opacity 0.3s`,
                  flexGrow: 1,

                  [theme.breakpoints.down(480)]: {
                    "& .MuiButton-endIcon": {
                      ml: 0,
                    },
                  },
                }}
                endIcon={
                  <Send
                    sx={{
                      color: "common.white",
                      transform: "rotate(180deg)",
                    }}
                  />
                }
                onClick={() => {
                  handleForm(textareaValue);
                  resetTextArea();
                }}
                ref={sendBtnRef}
              >
                {!matchesMobile &&
                  (sendToPlatformsStatus === "note"
                    ? localization.sendBtn.sendNote
                    : localization.sendBtn.default)}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
      {smilesPanelContainerRef.current &&
        reactDom.createPortal(
          <SmilesPanelBottom
            textareaRef={textareaRef}
            isOpen={isSmileBottomPanelOpen}
            // textareaValue={textareaValue}
            setTextareaValue={setTextareaValue}
            toggleSmilesPanelBottom={toggleSmilesPanelBottom}
          />,
          smilesPanelContainerRef.current,
        )}
    </Fragment>
  );
}

export default SendMessageForm;
