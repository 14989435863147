import React, { useEffect, useRef, useState } from "react";
import { convertSecondstoTime } from "../../../../libs/helpers";
import { Box, CircularProgress, Typography } from "@mui/material";
import CircularProgressWithLabel from "../../../../modules/CircularProgressWithLabel";

function VideoMessage({ file, Id, status, onVideoMessageLoaded }) {
  const videoRef = useRef(null);
  const [playerSize, setPlayerSize] = useState();
  const [progress, setProgress] = useState(0);

  function getVideoDimensions(url) {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");

      video.addEventListener("loadedmetadata", function () {
        const maxWidthDimension = 500;
        const maxHeightDimension = 400;
        let width, height;

        if (video.videoWidth > video.videoHeight) {
          width =
            video.videoWidth > maxWidthDimension
              ? maxWidthDimension
              : video.videoWidth;
          height = (width / video.videoWidth) * video.videoHeight;
        } else {
          height =
            video.videoHeight > maxHeightDimension
              ? maxHeightDimension
              : video.videoHeight;
          width = (height / video.videoHeight) * video.videoWidth;
        }

        resolve({
          width,
          height,
        });
      });

      video.addEventListener("error", function () {
        reject("Ошибка при загрузке видео.");
      });

      video.src = url;

      video.load();
    });
  }
  async function applyPlayerSize(url) {
    const videoSize = await getVideoDimensions(url);
    setPlayerSize(videoSize);
  }

  async function handlePlayer(file) {
    await applyPlayerSize(file);
    if (videoRef.current) {
      const player = new window.Playerjs({
        id: videoRef.current.id,
        file: file,
      });

      onVideoMessageLoaded(Id);

      return () => {
        player.api("destroy");
      };
    }
  }
  useEffect(() => {
    if (file) {
      handlePlayer(file);
    }
  }, [file]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 99
          ? 99
          : prevProgress + 10 >= 99
          ? 99
          : prevProgress + 10,
      );
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  // if (status === "uploading") {
  //   playerStyle = {
  //     ...playerStyle,
  //     pointerEvents: "none",
  //   };
  // }

  return (
    <Box
      className="video-message__container"
      sx={
        playerSize
          ? { ...playerSize, position: "relative" }
          : { position: "relative" }
      }
    >
      {status === "uploading" && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 100000000000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            "&::after": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              bgcolor: "rgba(0, 0, 0, 0.45)",
              backdropFilter: "blur(2px)",
            },
          }}
        >
          <CircularProgressWithLabel
            value={progress}
            containerStyles={{ zIndex: 2 }}
            color={"secondary"}
            textColor="common.white"
            size={60}
          />
        </Box>
      )}
      <div
        id={`player-${Id}`}
        ref={videoRef}
        style={{ width: "100%", height: "100%" }}
      ></div>
    </Box>
  );
}

export default VideoMessage;
