import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { funnerBigLogo } from "../../libs/images";
import localizations from "../../localizations.json";
import { applicationType } from "../../applicationType";
import { Close } from "@mui/icons-material";

const PageContent = ({
  localization,
  reloadPage,
  handleRemoveToken,
  removeTokenBtn,
  message,
}) => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        py: applicationType === "extension" ? 5 : 0,
      }}
    >
      <Grid container spacing={5} alignItems={"center"} justifyContent="center">
        <Grid item xs={12}>
          <Box
            sx={{ display: "flex", maxWidth: 308, width: "100%", mx: "auto" }}
          >
            <img
              src={funnerBigLogo}
              alt=""
              style={{ display: "block", height: "auto", width: "100%" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            textAlign={"center"}
            color="primary.main"
            sx={{ fontWeight: "600 !important" }}
          >
            {message || localization.title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs="auto">
              <Button variant="contained" onClick={() => reloadPage()}>
                {localization.reload}
              </Button>
            </Grid>
            {removeTokenBtn && (
              <Grid item xs="auto">
                <Button variant="contained" onClick={() => handleRemoveToken()}>
                  {localization.clearCache}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

function AppLoadError({
  handleRemoveToken,
  message = null,
  removeTokenBtn = true,
}) {
  const [localization, setLocalization] = useState(
    localizations["he"].appLoadErr,
  );
  const [showDialog, setShowDialog] = useState(true);

  const closeDialog = () => {
    setShowDialog(false);
  };

  const reloadPage = () => {
    window.location.reload();
  };

  return applicationType === "extension" ? (
    <Dialog
      open={showDialog}
      onClose={closeDialog}
      maxWidth={"sm"}
      fullWidth
      disablePortal
    >
      <DialogTitle>
        <Grid container justifyContent={"flex-end"}>
          <Grid item xs="auto">
            <IconButton onClick={closeDialog}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <PageContent
          localization={localization}
          reloadPage={reloadPage}
          handleRemoveToken={handleRemoveToken}
        />
      </DialogContent>
    </Dialog>
  ) : (
    <Box
      sx={{ position: "fixed", width: "100%", height: "100%", top: 0, left: 0 }}
    >
      <PageContent
        localization={localization}
        reloadPage={reloadPage}
        handleRemoveToken={handleRemoveToken}
        removeTokenBtn={removeTokenBtn}
        message={message}
      />
    </Box>
  );
}

export default AppLoadError;
