import React, { Fragment, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import localizations from "../../../localizations.json";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import { getTableLocaleText } from "../tableLocaleText";

const FilesTableView = ({
  files,
  handleDeleteFileFromGallery,
  onEditExistingNameClick,
  onScrollEndReached,
  noActions,
  onClickAction,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [localization, setLocalization] = React.useState(
    localizations["he"].settingsPopup.uploadFiles,
  );
  const open = Boolean(anchorEl);

  const columns = useMemo(() => {
    return [
      {
        field: "Name",
        headerName: localization.fileName,
        minWidth: 200,
        flex: 1,
      },
      {
        field: "DateCreate",
        headerName: localization.lastModified,
        minWidth: 280,
      },
    ].concat(
      !noActions
        ? [
            {
              field: "actions",
              type: "actions",
              getActions: (props) => [
                <GridActionsCellItem
                  onClick={() => {
                    handleDeleteFileFromGallery(selectedFile);
                    handleClose();
                  }}
                  label={localization.menu.delete}
                  showInMenu
                />,
                <GridActionsCellItem
                  onClick={() => {
                    onEditExistingNameClick(selectedFile);
                    handleClose();
                  }}
                  label={localization.menu.editName}
                  showInMenu
                />,
              ],
            },
          ]
        : [],
    );
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => {
      setSelectedFile(null);
    }, 300);
  };

  return (
    <Fragment>
      <DataGridPro
        rows={files}
        columns={columns}
        getRowId={(row) => row.Id}
        slots={{ toolbar: GridToolbar }}
        localeText={getTableLocaleText("he")}
        onRowClick={(params, event, details) => onClickAction(params.row)}
        onRowsScrollEnd={onScrollEndReached}
        scrollEndThreshold={200}
      />
      {/* <Table stickyHeader size={"small"}>
        <TableHead>
          <TableRow>
            <TableCell>{localization.fileName}</TableCell>
            <TableCell align="right">{localization.lastModified}</TableCell>
            {!noActions && (
              <TableCell align="right">{localization.actions}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((file) => (
            <TableRow
              key={file.Id}
              hover
              onClick={() => onClickAction(file)}
              sx={{ cursor: "pointer" }}
            >
              <TableCell>
                <Box
                  sx={{
                    maxWidth: 400,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {file.Name}
                </Box>
              </TableCell>
              <TableCell align="right">{file.DateCreate}</TableCell>
              {!noActions && (
                <TableCell align="right">
                  <IconButton onClick={(event) => handleClick(event, file)}>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table> */}
    </Fragment>
  );
};

export default FilesTableView;
