import { UseFetchPostAnonymos } from "../hooks/fetchFunctions";

export function IsJsonString(str) {
  if (!str) return false;
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
export function isEnglishString(str) {
  const englishRegex = /^[a-zA-Z0-9\s.,!?]+$/;

  return englishRegex.test(str);
}
export function isHebrewString(str) {
  const hebrewRegex = /[\u0590-\u05FF]+/;
  return hebrewRegex.test(str);
}

export function addEventListenerOnce(element, event, func) {
  if (!element._eventListeners) {
    element._eventListeners = {};
  }

  if (!element._eventListeners[event]) {
    element.addEventListener(event, func);
    element._eventListeners[event] = true;
  }
}
export function getContrastYIQ(hexcolor) {
  if (!hexcolor) return hexcolor;
  const r = parseInt(hexcolor.substr(1, 2), 16);
  const g = parseInt(hexcolor.substr(3, 2), 16);
  const b = parseInt(hexcolor.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= 128 ? "black" : "white";
}

export function convertSecondstoTime(given_seconds) {
  const dateObj = new Date(given_seconds * 1000);
  // const hours = dateObj.getUTCHours();
  const minutes = dateObj.getUTCMinutes();
  const seconds = dateObj.getSeconds();

  const timeString =
    minutes.toString().padStart(2, "0") +
    ":" +
    seconds.toString().padStart(2, "0");

  return timeString;
}

export const isUpdateTokenPage = () => {
  const currentURL = window.location.href;
  return currentURL.includes("app/settings/integration/apiforms");
};

export function searchObjectInArray(key, value, myArray) {
  ////console.log(key, value, myArray, 'searchObjectInArrayFunc')
  for (let i = 0; i < myArray.length; i++) {
    if (myArray[i][key] == value) {
      return myArray[i];
    }
  }
  ////console.log(myArray, "does not have this key or value", key,value)
}
export function changeNumber(number) {
  if (!number) return;
  const clearedNumber = number.replace(/\D/g, "");
  if (clearedNumber.length < 12) {
    const numberWithCountryCode = "972" + clearedNumber.slice(1);
    return numberWithCountryCode;
  } else {
    return clearedNumber;
  }
}

export function getElementOffset(el) {
  const box = el.getBoundingClientRect();
  console.log(box);

  return {
    top: box.top + window.scrollY,
    right: box.right + window.scrollX,
    bottom: box.bottom + window.scrollY,
    left: box.left + window.scrollX,
  };
}

export function getGlobalScripsData() {
  return new Promise((resolve, reject) => {
    let getScripts = setInterval(() => {
      const scripts = document.querySelectorAll("script");
      if (scripts) {
        [...scripts].map((script) => {
          if (
            script.textContent.includes("window.dataLayer = window.dataLayer")
          ) {
            const scriptString = String(script.textContent);
            // const arr = scriptString.split('(');
            // if (arr) {
            // const obj = JSON.parse(arr[1].slice(0, -1));
            // const userglobalid = obj.userglobalid;
            let userGlobalIdMatch = scriptString.match(
              /"userglobalid":\s*"([^"]*)"/,
            );
            let userGlobalId = userGlobalIdMatch ? userGlobalIdMatch[1] : null;

            let companyIdMatch = scriptString.match(/"companyid":\s*"([^"]*)"/);
            let companyId = companyIdMatch ? companyIdMatch[1] : null;

            let companyNameMatch = scriptString.match(
              /"copmanyname":\s*"((?:[^"\\]|\\.)*)"/,
            );
            let companyName = companyNameMatch
              ? companyNameMatch[1].replace(/\\\"/g, '"')
              : null;

            let userFullNameMatch = scriptString.match(
              /"userfullname":\s*"((?:[^"\\]|\\.)*)"/,
            );
            let userFullName = userFullNameMatch
              ? userFullNameMatch[1].replace(/\\\"/g, '"')
              : null;

            if (userGlobalId) {
              clearInterval(getScripts);

              // Resolve the promise with the obtained data
              resolve({
                globalID: userGlobalId,
                companyID: companyId,
                companyName: companyName,
                userfullname: userFullName,
              });
            }
            // }
          }
        });
      }
    }, 10);
  });
}

export function filterUniqueUsers(users) {
  const uniqueUsers = [];
  const phoneNumbers = new Set();

  users.forEach((user) => {
    if (!phoneNumbers.has(user.UserDisplayPhoneNumber)) {
      uniqueUsers.push(user);
      phoneNumbers.add(user.UserDisplayPhoneNumber);
    }
  });

  return uniqueUsers;
}

export const displayMessageDate = (date) => {
  const targetDate = new Date(date);
  const currentDate = new Date();
  targetDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  const timeDifferenceInDays =
    (currentDate - targetDate) / (24 * 60 * 60 * 1000);

  return timeDifferenceInDays;
};

export const getFileType = (str) => {
  if (!str) return null;
  const splitFileName = str.split(".");
  const fileType = splitFileName[splitFileName.length - 1].toLowerCase();
  return fileType;
};

export function generateUniqueId(existingIds = [], length = 5) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  let uniqueId = "";
  let isUnique = false;

  while (!isUnique) {
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    if (!existingIds.includes(result)) {
      uniqueId = result;
      isUnique = true;
    }
  }

  return uniqueId;
}

export function isEmptyObject(obj) {
  for (var i in obj) {
    if (obj.hasOwnProperty(i)) {
      return false;
    }
  }
  return true;
}

export function getCurrentDateFormatted() {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  return `${year}-${formattedMonth}-${formattedDay}`;
}

export const getDateParts = (dateParam = null) => {
  const date = dateParam || new Date();
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear());
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const milliseconds = String(date.getMilliseconds()).padStart(7, "0");

  return {
    day,
    month,
    year,
    hours,
    minutes,
    seconds,
    milliseconds,
  };
};

export const getInitials = (name) => {
  const names = name.split(" ");
  const initials = names.map((n) => n[0]).join("");
  return initials.toUpperCase();
};

export const inputIconButtonStyles = {
  "& .MuiInputAdornment-root": {
    opacity: 0,
    transition: "opacity 0.15s ease 0s",
  },
  "&:hover .MuiInputAdornment-root": {
    opacity: 1,
  },
};

export function findDuplicates(arr, key) {
  if (!arr || !key) return {};
  const valueIndices = {};
  const duplicates = {};

  arr.forEach((obj, index) => {
    const value = obj[key];
    if (valueIndices[value]) {
      valueIndices[value].push(index);
    } else {
      valueIndices[value] = [index];
    }
  });

  for (const value in valueIndices) {
    if (valueIndices[value].length > 1) {
      duplicates[value] = valueIndices[value];
    }
  }

  return duplicates;
}

export function isMobileDevice() {
  return /Mobi/i.test(window.navigator.userAgent);
}

export async function getFileLinkFromServer(e) {
  try {
    const file = e.target.files?.[0];
    if (!file) {
      return console.error("No file selected");
    }

    const formData = new FormData();
    if (file.type.startsWith("image/")) {
      formData.append("image", file);
      const response = await UseFetchPostAnonymos(
        "/api/services/GetImageLink",
        formData,
        "config",
      );
      const { data } = response;
      if (data?.result === true) {
        return {
          Name: file.name,
          Link: data.link,
        };
      } else return null;
    } else {
      formData.append("file", file);
      const response = await UseFetchPostAnonymos(
        "/api/whatsapp/SaveFile",
        formData,
        "config",
      );
      const { data } = response;
      console.log("data: ", data);
      if (data?.result === true) {
        return {
          Name: file.name,
          Link: data.link,
        };
      } else return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  } finally {
    e.target.value = null;
  }
}

export const GetFileTypeByLink = (link) => {
  const format = "." + getFileFormatFromURL(link);
  let mediaType = "";

  if (
    format.toLowerCase() === ".png" ||
    format.toLowerCase() === ".jpg" ||
    format.toLowerCase() === ".jpeg"
  ) {
    mediaType = "image";
  } else {
    var fileType = "";
    switch (format.toLowerCase()) {
      case ".pdf":
        fileType = "pdf";
        break;
      case ".xlsx":
        fileType = "xlsx";
        break;
      case ".mp3":
        fileType = "audio";
        break;
      case ".m4a":
        fileType = "audio";
        break;
      case ".z": /// android
        fileType = "audio";
        //fileLink = model.imageLink.Replace(".z", ".ogg");
        break;
      case ".acc":
        fileType = "audio";
        break;
      case ".aac":
        fileType = "audio";
        break;
      case ".3gp":
        fileType = "audio";
        break;
      case ".amr":
        fileType = "audio";
        break;
      case ".ogv":
        fileType = "audio";
        break;
      case ".mp4":
        fileType = "video";
        break;
      case ".zip":
        fileType = "zip";
        break;
      case ".mpeg":
        fileType = "video";
        break;
      case ".mov":
        fileType = "video";
        break;
      case ".docx":
        fileType = "docx";
        break;
      case ".gif":
        fileType = "image";
        break;
      case ".png":
        fileType = "image";
        break;
      case ".jpg":
        fileType = "image";
        break;
      case ".jpeg":
        fileType = "image";
        break;
      default:
        fileType = "document";
        break;
    }
    mediaType = fileType;
  }

  return mediaType;
};

export async function syncTimeout(delay) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

export function getFileFormatFromURL(url) {
  const fileExtension = url.split(".").pop();
  return fileExtension.toLowerCase();
}

export const isValidUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const isDuplicate = (array, key, value, index) => {
  if (index <= 0 || index >= array.length) {
    return false;
  }

  for (let i = 0; i < index; i++) {
    if (array[i][key] === value) {
      return true;
    }
  }

  return false;
};
