import React, { Fragment, useEffect, useState } from "react";
import { dateRangeIcon, download, taskIcon } from "../../../libs/images";
import { IsJsonString } from "../../../libs/helpers";
import localizations from "../../../localizations.json";
import {
  CopyAllOutlined,
  Download,
  EmojiEmotionsOutlined,
  ReplyOutlined,
  ShortcutOutlined,
  Star,
  StarOutline,
} from "@mui/icons-material";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";

function MessagesContextMenu({
  messagesContextRef,
  messagesContextState,
  closeMessagesContext,
  handleSendReply,
  selectedChatUser,
  onForwardContextClick,
  forwardMessagesWebhookEnabled,
  sendSingleMessageToWebhook,
  forwardMessagesToWebhook,
  toggleMessageInWebhookList,
  sendAllMessagesToWebhook,
  closeForwardMessage,
  updateMessageFavourite,
  setForwardMessagesWebhookSelectable,
  textareaRef,
}) {
  const [localization, setLocalization] = useState(
    localizations["he"].chat.messages.context,
  );
  const [showForwardWebhookSubmenu, setShowForwardWebhookSubmenu] =
    useState(false);

  const mediaTypes = [
    "document",
    "docx",
    "image",
    "video",
    "pdf",
    "xlsx",
    "zip",
  ];
  const isMediaFileType = mediaTypes.includes(
    messagesContextState?.message?.FileType,
  );
  let downloadFile;
  if (isMediaFileType) {
    const fileUrl = messagesContextState.message.File;
    const fileUrlParts = fileUrl.split("/");
    const fileName = fileUrlParts[fileUrlParts.length - 1];

    downloadFile = {
      url: fileUrl,
      name: fileName,
    };
  }

  function copyToClipboard(message) {
    let text = "";
    if (message?.FileType === "template") {
      if (IsJsonString(message.Message)) {
        const templateObject = JSON.parse(message.Message);
        text = templateObject["BODY"].text;
      } else {
        text = message.Message;
      }
    } else {
      text = message.Message;
    }

    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Could not copy text: ", err);
      },
    );
    closeMessagesContext();
  }
  function handleReply(message) {
    handleSendReply({
      isReply: true,
      message: message,
      userName: selectedChatUser.FullName,
    });
    textareaRef.current?.focus();
    closeMessagesContext();
  }
  function handleForward(message) {
    onForwardContextClick(message);
    closeMessagesContext();
  }

  const handleCloseContextMenuFromWebhook = () => {
    closeMessagesContext();
    setTimeout(() => {
      setShowForwardWebhookSubmenu(false);
    }, 300);
  };

  //! Forward messages to webhook
  const forwardSingleMessage = (message) => {
    sendSingleMessageToWebhook(message);
    closeForwardMessage();
    handleCloseContextMenuFromWebhook();
  };
  const handleToggleMessageInForwardWebhook = (messageId) => {
    toggleMessageInWebhookList(messageId);
    setForwardMessagesWebhookSelectable(true);
    closeForwardMessage();
    handleCloseContextMenuFromWebhook();
  };
  const forwardAllMessages = () => {
    sendAllMessagesToWebhook();
    closeForwardMessage();
    handleCloseContextMenuFromWebhook();
  };

  const handleDownload = (url, fileName) => {
    // fetch(url)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const url = window.URL.createObjectURL(new Blob([blob]));
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.download = fileName || "downloaded-file";
    //     document.body.appendChild(link);

    //     link.click();

    //     document.body.removeChild(link);
    //     window.URL.revokeObjectURL(url);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching the file:", error);
    //   }).finally(() => {
    //     closeMessagesContext();
    //   });

    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = fileName || "video.mp4";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    closeMessagesContext();
  };

  return (
    <Menu
      open={Boolean(messagesContextState.anchor)}
      anchorEl={messagesContextState.anchor}
      onClose={() => {
        handleCloseContextMenuFromWebhook();
      }}
      anchorReference="anchorPosition"
      anchorPosition={
        messagesContextState.anchor !== null
          ? {
              top: messagesContextState.mouseY,
              left: messagesContextState.mouseX,
            }
          : undefined
      }
      slotProps={{
        paper: {
          sx: {
            borderRadius: 1,
            border: 0,
            borderLeftWidth: 5,
            borderStyle: "solid",
            borderColor: "primary.main",
          },
        },
      }}
    >
      {showForwardWebhookSubmenu
        ? [
            <MenuItem
              key={"single-webhook-reply"}
              onClick={() => forwardSingleMessage(messagesContextState.message)}
            >
              <ListItemIcon>
                <ReplyOutlined />
              </ListItemIcon>
              <ListItemText>Single</ListItemText>
            </MenuItem>,
            <MenuItem
              key={"bulk-webhook-reply"}
              onClick={() =>
                handleToggleMessageInForwardWebhook(
                  messagesContextState.message.Id,
                )
              }
            >
              <ListItemIcon>
                <ReplyOutlined />
              </ListItemIcon>
              <ListItemText>Bulk</ListItemText>
            </MenuItem>,
            <MenuItem
              key={"all-webhook-reply"}
              onClick={() => forwardAllMessages()}
            >
              <ListItemIcon>
                <ReplyOutlined />
              </ListItemIcon>
              <ListItemText>All</ListItemText>
            </MenuItem>,
          ]
        : [
            <MenuItem
              onClick={() => handleReply(messagesContextState.message)}
              key={"reply"}
            >
              <ListItemIcon>
                <ReplyOutlined />
              </ListItemIcon>
              <ListItemText>{localization.reply}</ListItemText>
            </MenuItem>,
            <MenuItem
              onClick={() => handleForward(messagesContextState.message)}
              key={"forward"}
            >
              <ListItemIcon>
                <ShortcutOutlined />
              </ListItemIcon>
              <ListItemText>{localization.forward}</ListItemText>
            </MenuItem>,
            forwardMessagesWebhookEnabled && (
              <MenuItem
                onClick={() => setShowForwardWebhookSubmenu(true)}
                key={"forward-webhook"}
              >
                <ListItemIcon>
                  <ShortcutOutlined />
                </ListItemIcon>
                <ListItemText>{localization.forward} via Webhook</ListItemText>
              </MenuItem>
            ),
            <MenuItem
              onClick={() => handleReply(messagesContextState.message)}
              key={"emoji-reply"}
            >
              <ListItemIcon>
                <EmojiEmotionsOutlined />
              </ListItemIcon>
              <ListItemText>{localization.replyWithReaction}</ListItemText>
            </MenuItem>,
            <MenuItem
              onClick={(e) => copyToClipboard(messagesContextState.message)}
              key={"copy-to-clipboard"}
            >
              <ListItemIcon>
                <CopyAllOutlined />
              </ListItemIcon>
              <ListItemText>{localization.copy}</ListItemText>
            </MenuItem>,
            <MenuItem
              key={"make-favorite"}
              onClick={() => {
                closeMessagesContext();
                updateMessageFavourite(
                  messagesContextState.message.Id,
                  !Boolean(messagesContextState.message.isFavourite),
                );
              }}
            >
              <ListItemIcon>
                {messagesContextState.message?.isFavourite ? (
                  <Star />
                ) : (
                  <StarOutline />
                )}
              </ListItemIcon>
              <ListItemText>
                {messagesContextState.message?.isFavourite
                  ? "Remove favourite"
                  : "Make favourite"}
              </ListItemText>
            </MenuItem>,
            isMediaFileType && (
              <MenuItem
                onClick={() =>
                  handleDownload(downloadFile.url, downloadFile.name)
                }
                id="download-file-button"
                key={"donwload"}
              >
                <ListItemIcon>
                  <Download />
                </ListItemIcon>
                <ListItemText>{localization.download}</ListItemText>
              </MenuItem>
            ),
          ]}

      {/*
  
        {isMediaFileType && (
          <li className="context-menu__actions-item context-menu__emojy reactions">
            <button
              id="download-file-button"
              className="context-menu__btn"
              onClick={() =>
                handleDownload(downloadFile.url, downloadFile.name)
              }
              // target="_blank" rel="noreferrer"
            >
              <Download sx={{ opacity: 0.54, width: 20, height: 20 }} />
              {localization.download}
            </button>
          </li>
        )} */}
    </Menu>
    //     <div
    //       className="context-menu__content active"
    //       ref={messagesContextRef}
    //       style={{
    //         left: messagesContextState.coords?.x,
    //         top: messagesContextState.coords?.y,
    //       }}
    //     >
    //       <ul className="context-menu__actions">
    //         <li className="context-menu__actions-item context-menu__emojy">
    //           <button
    //             type="button"
    //             className="context-menu__btn context-menu__btn--reply-text"
    //             onClick={() => handleReply(messagesContextState.message)}
    //           >
    //             <img src={taskIcon} alt="" />
    //             {localization.reply}
    //           </button>
    //         </li>
    //         <li className="context-menu__actions-item context-menu__emojy">
    //           <button
    //             type="button"
    //             className="context-menu__btn context-menu__btn--reply-text"
    //             onClick={() => handleForward(messagesContextState.message)}
    //           >
    //             <ShortcutOutlined
    //               sx={{ fontSize: 20, color: "rgba(0, 0, 0, 0.54)" }}
    //             />
    //             {localization.forward}
    //           </button>
    //         </li>
    //         <li className="context-menu__actions-item context-menu__emojy reactions">
    //           <button
    //             type="button"
    //             className="context-menu__btn reactions-toggle reply-text"
    //             onClick={() => handleReply(messagesContextState.message)}
    //           >
    //             <img src={dateRangeIcon} alt="" />
    //             {localization.replyWithReaction}
    //           </button>
    //           <ul className="context-reply-emojy smile-to-react__list context-smiles">
    //             <li className="smile-to-react__item">👍</li>
    //             <li className="smile-to-react__item">🙂</li>
    //             <li className="smile-to-react__item">🔥</li>
    //             <li className="smile-to-react__item">❤️</li>
    //             <li className="smile-to-react__item">😂</li>
    //             <li className="smile-to-react__item smile-to-react__item_plus reply_emoji">
    //               +
    //             </li>
    //           </ul>
    //         </li>
    //         <li className="context-menu__actions-item context-menu__emojy reactions">
    //           <button
    //             type="button"
    //             id="copy-text-button"
    //             className="context-menu__btn"
    //             onClick={(e) => copyToClipboard(messagesContextState.message)}
    //           >
    //             <img src={dateRangeIcon} alt="" />
    //             <span id="copy-text-message-button-label">{localization.copy}</span>
    //           </button>
    //         </li>
    //         {isMediaFileType && (
    //           <li className="context-menu__actions-item context-menu__emojy reactions">
    //             <button
    //               id="download-file-button"
    //               className="context-menu__btn"
    //               onClick={() =>
    //                 handleDownload(downloadFile.url, downloadFile.name)
    //               }
    //               // target="_blank" rel="noreferrer"
    //             >
    //               <Download sx={{ opacity: 0.54, width: 20, height: 20 }} />
    //               {localization.download}
    //             </button>
    //           </li>
    //         )}

    //         {/* Uncomment when will be ready
    // <li class='context-menu__actions-item'>
    //   <button type='button' id='delete-message-button' class='context-menu__btn'>
    //     <img src='../../../assets/img/msg-popover/DeleteOutlined.svg' alt='' />
    //     <span>
    //       Delete message
    //     </span>
    //   </button>
    // </li>
    // */}
    //       </ul>
    //     </div>
  );
}

export default MessagesContextMenu;
