import React, { useMemo } from "react";
import { closeIcon } from "../../../../libs/images";
import { IsJsonString } from "../../../../libs/helpers";

function TemplateReplyTemplate({
  sentOrReceived,
  userName,
  text,
  templatesList,
}) {
  const doc = useMemo(() => {
    let doc = IsJsonString(text) ? JSON.parse(text) : text;
    if (doc?.components) {
      const currentTemplateInList = templatesList.templates.find(
        (item) => item.name === doc.name,
      );
      if (currentTemplateInList) {
        const paramsObject = JSON.parse(JSON.stringify(currentTemplateInList));

        // console.log(paramsObject);
        let newDoc = {};
        paramsObject.components.forEach((comp) => {
          if (comp.type === "BUTTONS") {
            newDoc["BUTTONS"] = {
              buttons: comp.buttons,
            };
            if (doc.components.some((item) => item.type === "button")) {
              const docBtnComp = doc.components.find(
                (item) => item.type === "button",
              );
              const urlBtn = newDoc["BUTTONS"].buttons.find(
                (btn) => btn.type === "URL",
              );
              urlBtn.url = urlBtn.url.replace(
                `{{1}}`,
                docBtnComp.parameters[0]?.text,
              );
            }
          } else if (comp.type === "HEADER") {
            newDoc["HEADER"] = {
              format: comp.format,
            };

            if (doc.components.some((item) => item.type === "HEADER")) {
              const params = doc.components.find(
                (item) => item.type === "HEADER",
              ).parameters;

              if (comp.format === "LOCATION") {
              } else if (comp.format === "DOCUMENT") {
                newDoc["HEADER"].text = params[0].document.link;
              } else if (comp.format === "IMAGE") {
                newDoc["HEADER"].text = params[0].image.link;
              } else if (comp.format === "VIDEO") {
                newDoc["HEADER"].text = params[0].video.link;
              } else {
                newDoc["HEADER"].text = comp.text;
                params.forEach((param, index) => {
                  newDoc["HEADER"].text = newDoc[comp.type].text.replace(
                    `{{${index + 1}}}`,
                    param.text,
                  );
                });
              }
            } else {
              newDoc["HEADER"].text = comp.text;
            }
          } else {
            newDoc[comp.type] = {
              text: comp.text,
            };

            if (doc.components.some((item) => item.type === comp.type)) {
              const params = doc.components.find(
                (item) => item.type === comp.type,
              ).parameters;

              params.forEach((param, index) => {
                newDoc[comp.type].text = newDoc[comp.type].text.replace(
                  `{{${index + 1}}}`,
                  param.text,
                );
              });
            }
          }
        });

        doc = newDoc;
      }
    }

    return doc;
  }, [text, templatesList?.templates]);

  return (
    <div className="message__upload-container message__upload-container_reply">
      <a
        href="#"
        className={`message-attached-reply message__reply-audio reply ${
          sentOrReceived === " incoming" ? "incoming" : "outcoming"
        }`}
      >
        <div className="reply__text-part">
          <p className="reply__top-text">
            {sentOrReceived == "incoming" ? userName : "אתה"}
          </p>
          <div className="reply__info">
            <p className="reply__text">{doc?.["BODY"]?.text}</p>
          </div>
        </div>
      </a>
    </div>
  );
}

export default TemplateReplyTemplate;
