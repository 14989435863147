import { Box, CircularProgress, Typography } from "@mui/material";

export default function CircularProgressWithLabel({
  containerStyles = {},
  textColor,
  ...props
}) {
  let containerDefaultStyles = {
    position: "relative",
    display: "inline-flex",
  };

  return (
    <Box sx={{ ...containerDefaultStyles, ...containerStyles }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color={textColor || "text.secondary"}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
