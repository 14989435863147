import React, { useEffect, useState } from "react";
import { closeIcon } from "../../../libs/images";
import TextReplyTemplate from "./Templates/TextReplyTemplate";
import TemplateReplyTemplate from "./Templates/TemplateReplyTemplate";
import { IsJsonString } from "../../../libs/helpers";
import AudioReplyTemplate from "./Templates/AudioReplyTemplate";
import VideoReplyTemplate from "./Templates/VideoReplyTemplate";
import ContactsReplyTemplate from "./Templates/ContactsReplyTemplate";
import LocationReplyTemplate from "./Templates/LocationReplyTemplate";
import FileReplyTemplate from "./Templates/FileReplyTemplate";

function SendReplyTemplate({ sendReply, closeHandler, templatesList }) {
  // const [fileType, setFileType] = useState(null);
  // const [file, setFile] = useState(null);
  // const [reply, setReply] = useState(null);
  // const [sentOrReceived, setSentOrReceived] = useState(null);
  // const [text, setText] = useState("");
  // const [object, setObject] = useState(null);

  let fileType = null,
    file = null,
    reply = null,
    sentOrReceived = null,
    text = "",
    object = null;

  // useEffect(() => {
  //   const {
  //     Reply,
  //     Type: sentOrRecieved,
  //     FileType,
  //     File: file,
  //     Object: object,
  //     Message: text,
  //   } = sendReply.message;

  //   setFileType(FileType);
  //   setFile(file);
  //   setReply(Reply);
  //   setSentOrReceived(sentOrRecieved);
  //   setText(text);
  //   setObject(object);
  // }, [sendReply]);
  if (sendReply) {
    const { Reply, Type, FileType, File, Object, Message } = sendReply.message;

    fileType = FileType;
    file = File;
    reply = Reply;
    sentOrReceived = Type;
    text = Message;
    object = Object;
  }

  return (
    <div className="messages__context-reply context-reply-message reactions-popup">
      <div className="context-reply-message__body">
        <button
          type="button"
          className="context-reply-message__close"
          onClick={closeHandler}
        >
          <img src={closeIcon} alt="" />
        </button>
        <div className="message__upload-container message__upload-container_reply">
          {fileType === "text" || fileType === "reply_button" ? (
            <TextReplyTemplate
              sentOrReceived={sentOrReceived}
              userName={sendReply.userName}
              text={text}
            />
          ) : fileType === "template" ? (
            <TemplateReplyTemplate
              sentOrReceived={sentOrReceived}
              userName={sendReply.userName}
              text={text}
              templatesList={templatesList}
            />
          ) : fileType === "audio" ? (
            <AudioReplyTemplate
              sentOrReceived={sentOrReceived}
              userName={sendReply.userName}
              file={file}
            />
          ) : fileType === "video" ? (
            <VideoReplyTemplate
              sentOrReceived={sentOrReceived}
              userName={sendReply.userName}
              file={file}
            />
          ) : fileType === "contacts" ? (
            <ContactsReplyTemplate
              sentOrReceived={sentOrReceived}
              object={object}
            />
          ) : fileType === "location" ? (
            <LocationReplyTemplate
              sentOrReceived={sentOrReceived}
              userName={sendReply.userName}
              object={object}
            />
          ) : ["pdf", "docx", "xlsx", "zip", "image"].includes(fileType) ? (
            <FileReplyTemplate
              sentOrReceived={sentOrReceived}
              userName={sendReply.userName}
              fileType={fileType}
              file={file}
            />
          ) : (
            <TextReplyTemplate
              sentOrReceived={sentOrReceived}
              userName={sendReply.userName}
              text={text}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SendReplyTemplate;
