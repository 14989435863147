import { Close, SearchOutlined, SyncOutlined } from "@mui/icons-material";
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  List,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import ConversationCase from "./ConversationCase";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import {
  UseFetchPost,
  UseFetchPostAnonymos,
} from "../../../../hooks/fetchFunctions";
import { enqueueSnackbar } from "notistack";
import localizations from "../../../../localizations.json";
import { sortListByParam } from "../../../../libs/messages";
import SingleMessageReplyTemplate from "../../SingleMessage/SingleMessageReplyTemplate";
import InfoMessageTemplate from "../../SingleMessage/InfoMessageTemplate";
import SingleMessageTemplate from "../../SingleMessage/SingleMessageTemplate";
import { IsJsonString } from "../../../../libs/helpers";
import axios from "axios";
import ChatMessagesPreview from "../../Actions/ChatMessagesPreview";

function ChatUserConversations({
  anchorEl,
  onClose,
  chatUserConversations,
  setChatUserConversations,
  funnerUsers,
  chatCustomizationSettings,
  userConfig,
  onMessagesContext,
  onEmojiReplyIconClicked,
  scrollToMessage,
  templatesList,
  chatUsersGlobalRef,
  forwardMessage,
  toggleMessageInForwardList,
  handleMediaPreview,
  businessInfo,
  chatUserConversationsLoading,
}) {
  const localization =
    localizations["he"].chat.chatHeader.chatWindow.conversations;

  const [searchFilter, setSearchFilter] = useState("");
  const [dateFilter, setDateFilter] = useState({
    start: null,
    end: null,
  });
  const [selectedConversation, setSelectedConversation] = useState(null);

  const [showMessagesDialog, setShowMessagesDialog] = useState(false);
  const [showMessagesList, setShowMessagesList] = useState([]);

  const webhook = useMemo(() => {
    let webhook = null,
      status = false;
    if (IsJsonString(businessInfo?.CompanyPlatforms)) {
      const platformsObject = JSON.parse(businessInfo.CompanyPlatforms);
      const webHooksList = platformsObject.webHooks;
      if (webHooksList) {
        const findedConversationCaseWebhook = webHooksList.find(
          (webhook) => webhook.WebhookIdentifier === "conversationCaseWebhook",
        );
        if (findedConversationCaseWebhook) {
          webhook = findedConversationCaseWebhook.WebhookUrl;
          const webhookActiveStates = platformsObject.webHooksActiveStates;
          status = webhookActiveStates?.conversationCaseWebhook ?? false;
        }
      }
    }
    return { url: webhook, status };
  }, [businessInfo?.CompanyPlatforms]);

  const handleSingleConversationCase = (conversationId, params) => {
    setChatUserConversations((prevList) => {
      const newList = [...prevList];
      const findedCaseIndex = newList.findIndex(
        (item) => item.conversationId === conversationId,
      );
      newList[findedCaseIndex] = { ...newList[findedCaseIndex], ...params };
      updateConversationLog(newList[findedCaseIndex]);
      return newList;
    });
  };
  async function updateConversationLog(updatedObject) {
    try {
      const res = await UseFetchPost(
        `/api/services/updateCovnersationLog`,
        {
          id: updatedObject.conversationId,
          name: updatedObject.name,
          priority: updatedObject.priority,
        },
        "history",
      );

      if (res?.data) {
        enqueueSnackbar(localization.notifications.success, {
          variant: "success",
        });
      } else {
        enqueueSnackbar(localization.notifications.errror, {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("updateConversationLog error: ", error);
      enqueueSnackbar(localization.notifications.errror, { variant: "error" });
    }
  }

  //! Filters
  const handleSearchFilter = (value) => {
    setSearchFilter(value);
  };
  const clearSearchFilter = () => {
    handleSearchFilter("");
  };
  const handleDateFilter = (params) => {
    setDateFilter((prevObject) => ({ ...prevObject, ...params }));
  };
  const clearFilters = () => {
    clearSearchFilter();
  };

  const parseDate = (dateString) => {
    const [day, month] = dateString.split(" ")[0].split("/");
    const currentYear = new Date().getFullYear();
    return dayjs(`${currentYear}-${month}-${day}`, "YYYY-MM-DD");
  };
  const filteredConversations = useMemo(() => {
    return chatUserConversations.filter((conv) => {
      const searchCond =
        searchFilter === ""
          ? true
          : (conv.name || conv.chatSerialSubjectNumber)
              ?.toLowerCase()
              .includes(searchFilter.toLowerCase()) ||
            conv.firstMessage?.Message?.toLowerCase().includes(
              searchFilter.toLowerCase(),
            ) ||
            conv.lastMessage?.Message?.toLowerCase().includes(
              searchFilter.toLowerCase(),
            );
      const itemStartDate = parseDate(conv.timeOpened);
      const itemEndDate = parseDate(conv.timeClosed);
      const dateCond =
        (dateFilter.end
          ? itemStartDate.isBefore(dateFilter.end.endOf("day"))
          : true) &&
        (dateFilter.start
          ? itemEndDate.isAfter(dateFilter.start.startOf("day"))
          : true);

      return searchCond && dateCond;
    });
  }, [chatUserConversations, searchFilter, dateFilter]);

  useEffect(() => {
    clearFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  //! Show messages dialog
  const onSingleConversationClick = (conversation) => {
    onClose();
    setSelectedConversation(conversation);
    const messages = sortListByParam(conversation.formatedMessages, "Id");
    setShowMessagesList(messages);
    setShowMessagesDialog(true);
  };

  const closeShowMessagesDialog = () => {
    setShowMessagesDialog(false);
    setTimeout(() => {
      setSelectedConversation(null);
      setShowMessagesList([]);
    }, 300);
  };

  async function sendRequestToWebhook(conversationCaseObject) {
    try {
      if (!webhook.url)
        return enqueueSnackbar(
          localization.singleConversation.sendWebhook.webhookNotSet,
          {
            variant: "error",
          },
        );
      if (webhook.url && !webhook.status)
        return enqueueSnackbar(
          localization.singleConversation.sendWebhook.webhookDisabled,
          { variant: "error" },
        );

      const res = await axios.post(webhook.url, conversationCaseObject);
      if (res?.status === 200) {
        enqueueSnackbar(localization.singleConversation.sendWebhook.success, {
          variant: "success",
        });
      } else {
        enqueueSnackbar(localization.singleConversation.sendWebhook.error, {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("sendRequestToWebhook error: ", error);
      enqueueSnackbar(localization.singleConversation.sendWebhook.error, {
        variant: "error",
      });
    }
  }

  return (
    <Fragment>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ maxWidth: 420 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ pt: 2, px: 2 }}>
                <TextField
                  fullWidth
                  size="small"
                  label={localization.filters.search}
                  value={searchFilter}
                  onChange={(e) => handleSearchFilter(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {searchFilter.length > 0 && (
                          <IconButton size="small" onClick={clearSearchFilter}>
                            <Close color="error" sx={{ fontSize: 20 }} />
                          </IconButton>
                        )}
                        <SearchOutlined sx={{ fontSize: 20 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ px: 2 }}>
                <Grid
                  container
                  spacing={1.5}
                  alignItems={"center"}
                  flexWrap={"nowrap"}
                >
                  <Grid item xs={5.75}>
                    <DatePicker
                      label={localization.filters.startDate}
                      value={dateFilter.start}
                      onChange={(newValue) =>
                        handleDateFilter({ start: newValue })
                      }
                      slotProps={{
                        textField: {
                          size: "small",
                          InputProps: {
                            sx: {
                              pr: 1,
                              fontSize: 15,
                              "& input": {
                                py: 1.25,
                                px: 1,
                                fontSize: 15,
                              },
                            },
                          },
                        },
                        inputAdornment: {
                          sx: { m: 0 },
                        },
                        openPickerButton: {
                          size: "small",
                        },
                        openPickerIcon: {
                          sx: {
                            fontSize: 18,
                          },
                        },
                        clearButton: {
                          size: "small",
                          mr: -0.5,
                        },
                        clearIcon: {
                          sx: {
                            fontSize: 18,
                          },
                        },
                        field: {
                          clearable: true,
                          onClear: () => handleDateFilter({ start: null }),
                        },
                      }}
                      disableFuture
                    />
                  </Grid>
                  <Grid item xs={0.5} textAlign={"center"}>
                    -
                  </Grid>
                  <Grid item xs={5.75}>
                    <DatePicker
                      label={localization.filters.endDate}
                      value={dateFilter.end}
                      onChange={(newValue) =>
                        handleDateFilter({ end: newValue })
                      }
                      slotProps={{
                        textField: {
                          size: "small",
                          InputProps: {
                            sx: {
                              pr: 1,
                              fontSize: 15,
                              "& input": {
                                py: 1.25,
                                px: 1,
                                fontSize: 15,
                              },
                            },
                          },
                        },
                        inputAdornment: {
                          sx: { m: 0 },
                        },
                        openPickerButton: {
                          size: "small",
                        },
                        openPickerIcon: {
                          sx: {
                            fontSize: 18,
                          },
                        },
                        clearButton: {
                          size: "small",
                          mr: -0.5,
                        },
                        clearIcon: {
                          sx: {
                            fontSize: 18,
                          },
                        },
                        field: {
                          clearable: true,
                          onClear: () => handleDateFilter({ end: null }),
                        },
                      }}
                      disableFuture
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {chatUserConversationsLoading ? (
                <Box sx={{ p: 0.75, pb: 2.5, textAlign: "center" }}>
                  <CircularProgress color="secondary" size={50} />
                </Box>
              ) : (
                <List
                  sx={{
                    maxHeight: 420,
                    overflow: "auto",
                    "&::-webkit-scrollbar": { display: "none" },
                  }}
                >
                  {filteredConversations.map((conversation) => {
                    const assignee =
                      funnerUsers?.find(
                        (assigneeUser) =>
                          assigneeUser.Udid === conversation.owner,
                      ) || null;

                    return (
                      <ConversationCase
                        conversation={conversation}
                        assignee={assignee}
                        handleSingleConversationCase={
                          handleSingleConversationCase
                        }
                        key={conversation.conversationId}
                        onSingleConversationClick={onSingleConversationClick}
                        selectedConversation={selectedConversation}
                        sendRequestToWebhook={sendRequestToWebhook}
                      />
                    );
                  })}
                </List>
              )}
            </Grid>
          </Grid>
        </Box>
      </Popover>

      <Dialog
        open={showMessagesDialog}
        onClose={closeShowMessagesDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Grid container spacing={1.5} alignItems="center">
            <Grid item flexGrow={1}>
              <Typography
                fontWeight={600}
                fontSize={20}
                display="inline-block"
                mr={1}
              >
                {selectedConversation?.name ||
                  selectedConversation?.chatSerialSubjectNumber}
              </Typography>

              <Typography
                fontSize={13}
                color="text.disabled"
                display="inline-block"
                sx={{
                  verticalAlign: "middle",
                }}
              >
                {selectedConversation?.timeOpened} -{" "}
                {selectedConversation?.timeClosed}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <IconButton
                onClick={() => {
                  closeShowMessagesDialog();
                  sendRequestToWebhook(selectedConversation);
                }}
              >
                <SyncOutlined />
              </IconButton>
            </Grid>
            <Grid item xs="auto">
              <IconButton onClick={() => closeShowMessagesDialog()}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {/* <Box className="root-whatsapp rtl chat w-0">
            <div
              className={`page whatsappPage ${
                "_" + (userConfig?.fontSize || "14")
              } width main-content ${
                userConfig.isMssgRTL === false ||
                userConfig.isMssgRTL === "false"
                  ? "swap-msgs"
                  : ""
              }`}
              style={{
                //?
                position: "static",
                width: "100%",
              }}
            >
              <Box
                className={"chat-content-wrap"}
                sx={{
                  boxShadow: "none !important",
                }}
              >
                <div
                  className={`chat-content perfect-scrollbar context-menu-container`}
                >
                  <Box
                    id="funner-global__messages-container"
                    className="messages-container"
                    // ref={messagesFeedRef}
                    // onScroll={(e) => {
                    //   const scroller = e.target;
                    //   scrollDirection =
                    //     scroller.scrollTop > prevScrollTop ? "bottom" : "top";
                    //   prevScrollTop = scroller.scrollTop;

                    //   if (
                    //     scroller.scrollHeight -
                    //       scroller.offsetHeight -
                    //       scroller.scrollTop >
                    //       100 &&
                    //     !isGoBotBtn
                    //   ) {
                    //     setIsGoBotBtn(true);
                    //     isGoBotBtnRef.current = true;
                    //   } else if (
                    //     scroller.scrollHeight -
                    //       scroller.offsetHeight -
                    //       scroller.scrollTop <=
                    //       100 &&
                    //     isGoBotBtn
                    //   ) {
                    //     isGoBotBtnRef.current = false;
                    //     setIsGoBotBtn(false);
                    //   }
                    // }}
                    sx={{
                      position: "static !important",
                      maxHeight: "65vh !important",
                      mx: "-16px !important",
                      px: "16px !important",
                      width: "calc(100% + 32px) !important",
                      background:
                        (chatCustomizationSettings?.find(
                          (option) => option.type === "conversationBackground",
                        )?.value === "image"
                          ? `url(${
                              chatCustomizationSettings.find(
                                (option) =>
                                  option.type === "conversationBackground",
                              ).customColor.backgroundImage
                            }) center / cover no-repeat`
                          : chatCustomizationSettings?.find(
                              (option) =>
                                option.type === "conversationBackground",
                            )?.value === "color"
                          ? chatCustomizationSettings.find(
                              (option) =>
                                option.type === "conversationBackground",
                            ).customColor.background
                          : "#fff") + "!important",
                      "&::-webkit-scrollbar": {
                        bgcolor: "rgba(255, 255, 255, 0.25) !important",
                      },
                    }}
                  >
                    {showMessagesList?.map((message, index) => {
                      if (message.FileType === "error")
                        return <Alert severity="error">{message.text}</Alert>;
                      if (message.FileType === "reaction") return null;
                      if (message.FileType === "buffer")
                        return (
                          <Box
                            sx={{
                              width: "100%",
                              height: "2px",
                              visibility: "hidden",
                            }}
                            // id={
                            //   chatType === "fireberry"
                            //     ? `fireberry-buffer-${message.Id}`
                            //     : `funner-buffer-${message.Id}`
                            // }
                            key={`buffer-${message.Id}`}
                          ></Box>
                        );

                      const prevMessage = showMessagesList[index - 1];
                      const isSameSender =
                        prevMessage?.Type === message.Type &&
                        prevMessage?.GlobalUserId === message.GlobalUserId;
                      const isFunnerAvatarVisible =
                        !isSameSender || prevMessage?.FileType === "info";

                      return message.Reply ? (
                        <SingleMessageReplyTemplate
                          key={message.TempId || message.Id}
                          message={message}
                          userConfig={userConfig}
                          onMessagesContext={onMessagesContext}
                          onEmojiReplyIconClicked={onEmojiReplyIconClicked}
                          scrollToReplyMessage={scrollToMessage}
                          templatesList={templatesList}
                          funnerUsers={funnerUsers}
                          isFunnerAvatarVisible={isFunnerAvatarVisible}
                          chatUsersGlobalRef={chatUsersGlobalRef}
                          chatCustomizationSettings={chatCustomizationSettings}
                          forwardMessage={forwardMessage}
                          toggleMessageInForwardList={
                            toggleMessageInForwardList
                          }
                          disableInteractions={true}
                        />
                      ) : message.FileType === "info" ? (
                        <InfoMessageTemplate
                          key={message.TempId || message.Id}
                          message={message}
                          userConfig={userConfig}
                          chatCustomizationSettings={chatCustomizationSettings}
                        />
                      ) : (
                        <SingleMessageTemplate
                          key={message.TempId || message.Id}
                          message={message}
                          userConfig={userConfig}
                          onMessagesContext={onMessagesContext}
                          onEmojiReplyIconClicked={onEmojiReplyIconClicked}
                          handleMediaPreview={handleMediaPreview}
                          templatesList={templatesList}
                          funnerUsers={funnerUsers}
                          isFunnerAvatarVisible={isFunnerAvatarVisible}
                          chatCustomizationSettings={chatCustomizationSettings}
                          forwardMessage={forwardMessage}
                          toggleMessageInForwardList={
                            toggleMessageInForwardList
                          }
                          disableInteractions={true}
                        />
                      );
                    })}
                  </Box>
                </div>
              </Box>
            </div>
          </Box> */}
          <ChatMessagesPreview
            userConfig={userConfig}
            chatCustomizationSettings={chatCustomizationSettings}
            showMessagesList={showMessagesList}
            scrollToMessage={scrollToMessage}
            templatesList={templatesList}
            funnerUsers={funnerUsers}
            chatUsersGlobalRef={chatUsersGlobalRef}
            handleMediaPreview={handleMediaPreview}
          />
        </DialogContent>
      </Dialog>
      {/* <ChatMessagesPreview
        open={showMessagesDialog}
        onClose={closeShowMessagesDialog}
        title={
          selectedConversation?.name ||
          selectedConversation?.chatSerialSubjectNumber
        }
        actions={
          <Fragment>
            <IconButton
              onClick={() => {
                onClose();
                sendRequestToWebhook(selectedConversation);
              }}
            >
              <SyncOutlined />
            </IconButton>

            <IconButton onClick={() => onClose()}>
              <Close />
            </IconButton>
          </Fragment>
        }
      /> */}
    </Fragment>
  );
}

export default ChatUserConversations;
