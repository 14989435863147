import React, {
  Fragment,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import localizations from "../../../localizations.json";
import {
  instagramRounded,
  powerlinkIcon,
  powerlinkIconOrange,
  priorityDocLogo,
  priorityLetterLogo,
  recordChatSmallIcon,
} from "../../../libs/images";
import SmilesPanelBottom from "../SmilesPanel/SmilesPanelBottom";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  SvgIcon,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Close,
  DescriptionOutlined,
  ImageOutlined,
  LinkOutlined,
  ShortcutOutlined,
  VideoFileOutlined,
} from "@mui/icons-material";
import { applicationType } from "../../../applicationType";
import { IsJsonString } from "../../../libs/helpers";
import SendMessageForm from "./SendMessageForm";
import FilesListLayout from "../../Settings/UploadFiles/FilesListLayout";
import { UseFetchGet } from "../../../hooks/fetchFunctions";
import ChatInputAreaIcons from "./ChatInputAreaIcons";

const ChatInputArea = memo(
  ({
    textareaRef,
    sendToPlatformsStatus,
    switchToChatType,
    selectedChatUser,
    toggleAccordion,
    businessInfo,
    isMessageSending,
    handleForm,
    isActive,
    handleUpload,
    sendUpload,
    sendUploadPriority,
    funnerUsers,
    userConfig,
    handlePrioritySendDocsWindow,
    isTemplateSending,
    resetTemplateFromChat,
    chatType,
    companyType,
    isSmileBottomPanelOpen,
    setIsSmileBottomPanelOpen,
    forwardMessage,
    handleForwardMessage,
    closeForwardMessage,
    forwardMessagesWebhookList,
    handleMessagesToWebhookPopup,
    forwardMessagesWebhookSelectable,
    resetForwardMessagesToWebhook,
    isButtonsPopupForForwardMessagesWebhook,
    forwardMessagesToWebhook,
    handleDirectFileUpload,
  }) => {
    const [localization, setLocalization] = useState(
      localizations["he"].chat.inputArea,
    );
    const isFireberryPage = window.location.href.includes("app.fireberry.com");

    //! Views and states
    const [isDragging, setIsDragging] = useState(false);
    // const [textareaValue, setTextareaValue] = useState("");
    const [chooseFromGallery, setChooseFromGallery] = useState(false);

    //! Refs
    const inputAreaRef = useRef(null);
    const smilesPanelContainerRef = useRef(null);

    //! Functions and handlers
    //* Drag&Drop and Copypaste
    const handleOnDrop = (e) => {
      e.preventDefault();
      handleUpload(e.dataTransfer.files);
      setIsDragging(false);
    };
    const handleOnDragEnter = (e) => {
      e.preventDefault();
      if (!isDragging) {
        setIsDragging(true);
      }
    };
    const handleOnDragLeave = (e) => {
      e.preventDefault();
      const rect = inputAreaRef.current.getBoundingClientRect();
      const isOutside =
        e.clientX < rect.left ||
        e.clientX > rect.right ||
        e.clientY < rect.top ||
        e.clientY > rect.bottom;

      if (isOutside) {
        setIsDragging(false);
      }
    };
    const handleOnDragOver = (e) => {
      e.preventDefault();
    };

    const toggleSmilesPanelBottom = () => {
      const newValue = !isSmileBottomPanelOpen;
      setIsSmileBottomPanelOpen(newValue);
    };

    return (
      <Box
        className={`pl-3 pr-3 pt-3 pb-3 box-shadow-1 chat-input-area ${
          isDragging ? "file-drag" : ""
        }`}
        ref={inputAreaRef}
        onDrop={
          // isFireberryPage ||
          // sendToPlatformsStatus === "note" ||
          // (sendToPlatformsStatus !== "note" && !isActive)
          //   ? null
          //   : handleOnDrop
          isFireberryPage || !isActive ? null : handleOnDrop
        }
        onDragEnter={isFireberryPage || !isActive ? null : handleOnDragEnter}
        onDragLeave={isFireberryPage || !isActive ? null : handleOnDragLeave}
        onDragOver={isFireberryPage || !isActive ? null : handleOnDragOver}
        sx={{
          pt: applicationType === "extension" ? 2.5 : 3,
          pb: applicationType === "extension" ? 2.5 : 4,
          // pt: 1.5,
          // pb: 1,
          pl: 1.75,
          pr: 3.25,
        }}
      >
        <div className={"chat-input-area__drag-preview"}>
          <div>Drag your file here to send</div>
        </div>

        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 10000000000000000000000000000n,
            bgcolor: "#eee",
            transform:
              forwardMessage.visible || forwardMessagesWebhookSelectable
                ? "translateY(0)"
                : "translateY(101%)",
            transition: "transform 0.3s ease 0s",
          }}
        >
          <Grid
            container
            width={"100%"}
            height={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
            spacing={3}
          >
            <Grid item>
              <Typography fontWeight={500} fontSize={20}>
                {
                  localizations["he"].chat.messages.forwardMessages
                    .selectedCount
                }
                :{" "}
                {forwardMessage.visible
                  ? forwardMessage.messagesList?.length
                  : forwardMessagesWebhookSelectable
                  ? forwardMessagesWebhookList.length
                  : 0}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                disabled={
                  forwardMessage.visible
                    ? !forwardMessage.messagesList?.length
                    : forwardMessagesWebhookSelectable
                    ? !forwardMessagesWebhookList.length
                    : true
                }
                sx={{
                  opacity:
                    (forwardMessage.visible &&
                      !forwardMessage.messagesList?.length) ||
                    (forwardMessagesWebhookSelectable &&
                      !forwardMessagesWebhookList.length)
                      ? 0.5
                      : 1,
                }}
                onClick={() =>
                  forwardMessage.visible
                    ? handleForwardMessage({
                        chatUsersPopup: true,
                      })
                    : forwardMessagesWebhookSelectable
                    ? isButtonsPopupForForwardMessagesWebhook
                      ? handleMessagesToWebhookPopup(true)
                      : forwardMessagesToWebhook(forwardMessagesWebhookList)
                    : null
                }
              >
                <ShortcutOutlined color="primary" sx={{ fontSize: 30 }} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  if (forwardMessage.visible) {
                    closeForwardMessage();
                  } else if (forwardMessagesWebhookSelectable) {
                    resetForwardMessagesToWebhook();
                  }
                }}
              >
                <Close color="primary" sx={{ fontSize: 30 }} />
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        <div ref={smilesPanelContainerRef} />
        {/* <SmilesPanelBottom
        textareaRef={textareaRef}
        isOpen={isSmileBottomPanelOpen}
        // textareaValue={textareaValue}
        // setTextareaValue={setTextareaValue}
        toggleSmilesPanelBottom={toggleSmilesPanelBottom}
      /> */}
        <form className="inputForm">
          {/* <div className="d-flex chat-input-actions"> */}
          <Grid
            container
            spacing={1.5}
            alignItems={applicationType === "web" ? "flex-end" : null}
          >
            <Grid item xs={12}>
              <Grid
                container
                spacing={3}
                rowSpacing={1.5}
                flexWrap={"wrap-reverse"}
              >
                <Grid item>
                  <div className="chat-input-actions__top">
                    <button
                      type="button"
                      className={`chat-input-actions__whatsapp chat-input-actions__top-btn ${
                        sendToPlatformsStatus === "whatsapp" ? "active" : ""
                      }`}
                      onClick={() => switchToChatType("whatsapp")}
                    >
                      <span className="icon">
                        {/* <img src="${whatsappChatSmallIcon}"alt="" /> */}
                        <svg
                          fill="#fff"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <g id="SVGRepo_iconCarrier">
                            <path d="M11.42 9.49c-.19-.09-1.1-.54-1.27-.61s-.29-.09-.42.1-.48.6-.59.73-.21.14-.4 0a5.13 5.13 0 0 1-1.49-.92 5.25 5.25 0 0 1-1-1.29c-.11-.18 0-.28.08-.38s.18-.21.28-.32a1.39 1.39 0 0 0 .18-.31.38.38 0 0 0 0-.33c0-.09-.42-1-.58-1.37s-.3-.32-.41-.32h-.4a.72.72 0 0 0-.5.23 2.1 2.1 0 0 0-.65 1.55A3.59 3.59 0 0 0 5 8.2 8.32 8.32 0 0 0 8.19 11c.44.19.78.3 1.05.39a2.53 2.53 0 0 0 1.17.07 1.93 1.93 0 0 0 1.26-.88 1.67 1.67 0 0 0 .11-.88c-.05-.07-.17-.12-.36-.21z" />
                            <path d="M13.29 2.68A7.36 7.36 0 0 0 8 .5a7.44 7.44 0 0 0-6.41 11.15l-1 3.85 3.94-1a7.4 7.4 0 0 0 3.55.9H8a7.44 7.44 0 0 0 5.29-12.72zM8 14.12a6.12 6.12 0 0 1-3.15-.87l-.22-.13-2.34.61.62-2.28-.14-.23a6.18 6.18 0 0 1 9.6-7.65 6.12 6.12 0 0 1 1.81 4.37A6.19 6.19 0 0 1 8 14.12z" />
                          </g>
                        </svg>
                      </span>
                      {localization.sendType.whatsapp}
                    </button>
                    <Tooltip
                      title={
                        <Box
                          dangerouslySetInnerHTML={{
                            __html: localization.sendType.noteTooltip,
                          }}
                        />
                      }
                      placement="top"
                    >
                      <button
                        type="button"
                        className={`chat-input-actions__record chat-input-actions__top-btn ${
                          sendToPlatformsStatus === "note" ? "active" : ""
                        }`}
                        onClick={() => switchToChatType("note")}
                      >
                        <span className="icon">
                          <img src={recordChatSmallIcon} alt="" />
                        </span>
                        {localization.sendType.note}
                      </button>
                    </Tooltip>

                    <div
                      className="funner-tooltip funner-tooltip--top"
                      style={{ display: "none" }}
                    >
                      <button
                        type="button"
                        className={`chat-input-actions__facebook chat-input-actions__top-btn _disabled-element ${
                          sendToPlatformsStatus === "facebook" ? "active" : ""
                        }`}
                        style={{ pointerEvents: "none" }}
                        // onClick={() => switchToChatType("facebook")}
                      >
                        <span className="icon">
                          <svg
                            width={24}
                            height={25}
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_348_9822)">
                              <path
                                d="M10.5266 19.633L10.5237 19.6323C10.2587 19.5614 9.97018 19.5783 9.71022 19.6942C9.7097 19.6945 9.70919 19.6947 9.70867 19.6949L7.92483 20.4812L7.92471 20.4813C7.89173 20.4958 7.8557 20.5021 7.81976 20.4994C7.78383 20.4968 7.74909 20.4853 7.7186 20.4661C7.68812 20.4469 7.66282 20.4205 7.64493 20.3892C7.62705 20.3579 7.61712 20.3228 7.61602 20.2867L7.61601 20.2866L7.56662 18.6887C7.5666 18.6879 7.56659 18.6872 7.56657 18.6864C7.5581 18.3434 7.40233 18.0313 7.15897 17.8141C5.51474 16.343 4.5 14.2107 4.5 11.7298C4.5 7.00868 8.18865 3.5 13 3.5C17.8112 3.5 21.5 7.01077 21.5 11.732C21.5 16.4531 17.8113 19.9618 13 19.9618C12.1334 19.9618 11.3048 19.8463 10.5266 19.633Z"
                                stroke="#fff"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M10.4781 9.87641L8.06367 13.4408C7.83147 13.7831 8.28354 14.1674 8.62876 13.9227C8.97398 13.6779 11.222 12.0908 11.222 12.0908C11.3073 12.0304 11.4114 11.9976 11.5186 11.9973C11.6257 11.9969 11.7301 12.0291 11.8159 12.0888L13.7371 13.4293C13.8734 13.5245 14.0294 13.5925 14.1953 13.6288C14.3612 13.6651 14.5334 13.669 14.701 13.6402C14.8686 13.6115 15.028 13.5507 15.1691 13.4617C15.3102 13.3728 15.4299 13.2575 15.5208 13.1234L17.9373 9.5609C18.1674 9.21861 17.7154 8.83234 17.3701 9.07711L14.7769 10.909C14.6916 10.9693 14.5875 11.0021 14.4803 11.0025C14.3732 11.0028 14.2688 10.9707 14.1831 10.9109L12.2618 9.57046C12.1255 9.47522 11.9695 9.4073 11.8036 9.371C11.6377 9.3347 11.4655 9.33081 11.2979 9.35956C11.1303 9.38831 10.9709 9.44908 10.8298 9.53804C10.6887 9.627 10.569 9.74222 10.4781 9.87641Z"
                                stroke="#fff"
                                strokeWidth="0.75"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_348_9822">
                                <rect
                                  width={24}
                                  height={24}
                                  fill="white"
                                  transform="translate(0 0.615234)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        {localization.sendType.facebook}
                      </button>
                      <div className="funner-tooltip__text">
                        <div className="funner-tooltip__text-content">
                          {localization.comingSoon}
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="chat-input-actions__instagram chat-input-actions__top-btn"
                      style={{ display: "none" }}
                    >
                      <span className="icon">
                        <img src={instagramRounded} alt="" />
                      </span>
                      {localization.sendType.instagram}
                    </button>
                  </div>
                </Grid>

                {applicationType !== "extension" && (
                  <Grid item>
                    <ChatInputAreaIcons
                      businessInfo={businessInfo}
                      toggleAccordion={toggleAccordion}
                      selectedChatUser={selectedChatUser}
                      sendToPlatformsStatus={sendToPlatformsStatus}
                      isActive={isActive}
                      chatType={chatType}
                      handleUpload={handleUpload}
                      toggleSmilesPanelBottom={toggleSmilesPanelBottom}
                      setChooseFromGallery={setChooseFromGallery}
                      handlePrioritySendDocsWindow={
                        handlePrioritySendDocsWindow
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SendMessageForm
                funnerUsers={funnerUsers}
                textareaRef={textareaRef}
                // setTextareaValue={setTextareaValue}
                isTemplateSending={isTemplateSending}
                // textareaValue={textareaValue}
                sendToPlatformsStatus={sendToPlatformsStatus}
                isActive={isActive}
                selectedChatUser={selectedChatUser}
                handleUpload={handleUpload}
                userConfig={userConfig}
                resetTemplateFromChat={resetTemplateFromChat}
                toggleAccordion={toggleAccordion}
                chatType={chatType}
                toggleSmilesPanelBottom={toggleSmilesPanelBottom}
                businessInfo={businessInfo}
                setChooseFromGallery={setChooseFromGallery}
                handlePrioritySendDocsWindow={handlePrioritySendDocsWindow}
                sendUpload={sendUpload}
                sendUploadPriority={sendUploadPriority}
                isMessageSending={isMessageSending}
                handleForm={handleForm}
                smilesPanelContainerRef={smilesPanelContainerRef}
                isSmileBottomPanelOpen={isSmileBottomPanelOpen}
              />
            </Grid>
          </Grid>

          {/* </div> */}
        </form>

        {console.log(chooseFromGallery)}
        <Dialog
          open={Boolean(chooseFromGallery)}
          onClose={() => setChooseFromGallery(false)}
          maxWidth={"lg"}
          fullWidth
        >
          <DialogTitle justifyContent={"flex-end"}>
            <IconButton onClick={() => setChooseFromGallery(false)}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <ToggleButtonGroup
              color="primary"
              value={chooseFromGallery}
              exclusive
              onChange={(event, newAlignment) => {
                setChooseFromGallery(newAlignment);
              }}
              aria-label="Platform"
              sx={{
                mb: 2,
              }}
            >
              <ToggleButton value="image">
                <ImageOutlined />
              </ToggleButton>
              <ToggleButton value="video">
                <VideoFileOutlined />
              </ToggleButton>
              <ToggleButton value="document">
                <DescriptionOutlined />
              </ToggleButton>
            </ToggleButtonGroup>
            {chooseFromGallery === "image" && (
              <FilesListLayout
                type={chooseFromGallery}
                companyId={null}
                FunnerUserId={null}
                noActions={true}
                onClickAction={(file) => {
                  handleDirectFileUpload(file);
                  setChooseFromGallery(false);
                }}
              />
            )}
            {chooseFromGallery === "video" && (
              <FilesListLayout
                type={chooseFromGallery}
                companyId={null}
                FunnerUserId={null}
                noActions={true}
                onClickAction={(file) => {
                  handleDirectFileUpload(file);
                  setChooseFromGallery(false);
                }}
              />
            )}
            {chooseFromGallery === "document" && (
              <FilesListLayout
                type={chooseFromGallery}
                companyId={null}
                FunnerUserId={null}
                noActions={true}
                onClickAction={(file) => {
                  handleDirectFileUpload(file);
                  setChooseFromGallery(false);
                }}
              />
            )}
          </DialogContent>
        </Dialog>
      </Box>
    );
  },
);

export default ChatInputArea;
